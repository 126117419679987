<template>
  <div class="modal modal-mask" id="modalAdmin" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content p-0">
        <div class="modal-header">
          <h5 class="modal-title">{{ action }} Admin</h5>
        </div>
        <div class="modal-body">
          <div class="form-group mb-2">
            <label for="form-name">Nama</label>
            <input
              type="email"
              class="form-control"
              id="form-name"
              placeholder="Nama"
              v-model="userToChange.username"
            />
          </div>
          <div class="form-group mb-2">
            <label for="form-email-admin">Email</label>
            <input
              type="email"
              class="form-control"
              id="form-email-admin"
              placeholder="Email"
              v-model="userToChange.email"
            />
          </div>
          <div class="form-group mb-2" v-if="!this.userToChange.id">
            <label for="form-password-admin">Kata Laluan</label>
            <input
              type="password"
              class="form-control"
              id="form-password-admin"
              placeholder="Password"
              v-model="userToChange.password"
            />
          </div>
          <div class="form-group mb-2">
            <label for="exampleFormControlInput1">Jenis Admin</label>
            <select
              class="form-select"
              aria-label="Default select example"
              v-model="userToChange.admin_type"
            >
              <option disabled selected :value="null">Pilih Jenis Admin</option>
              <option
                :selected="userToChange && userToChange.admin_type == type.vale"
                v-for="(type, index) in types"
                :key="index"
                :value="type.value"
              >
                {{ type.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="saveClicked">
            <div
              class="spinner-border spinner-border-sm text-light"
              role="status"
              v-if="isLoading"
            ></div>
            Simpan
          </button>
          <button type="button" class="btn btn-secondary" @click="close(null)">
            Batal
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../utils/API";
export default {
  props: ["user"],
  data() {
    return {
      userToChange: {
        username: null,
        email: null,
        admin_type: null,
        isLoading: false
      },
      action: "",
      types: [
        { value: "superadmin", name: "Super Admin" },
        { value: "admin_hq", name: "Admin HQ" },
        { value: "admin_management", name: "Admin Management" },
        { value: "admin_state", name: "Admin State" },
      ],
    };
  },
  methods: {
    close(data) {
      this.$emit("close", data);
    },
    async saveClicked() {
        this.isLoading = true
      if (this.userToChange && this.userToChange.id) {
        await API.put(`users/${this.userToChange.id}`, {
          email: this.userToChange.email,
          username: this.userToChange.username,
          admin_type: this.userToChange.admin_type,
        });
      } else {
        this.userToChange.role = 3;
        console.log(this.userToChange);
        await API.post(`users`, this.userToChange);
      }
        this.isLoading = true

      this.close("refresh");
    },
  },
  mounted() {
    if (this.user && this.user.id) {
      this.action = "Ubah";
      this.userToChange = { ...this.user };
    } else {
      this.action = "Tambah";
    }
  },
};
</script>

<style scoped>
.modal-mask {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>