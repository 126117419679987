<template>
  <div class="container mb-5">
    <div class="container-header mt-4 mb-4">
      <div class="row align-items-center px-3">
        <div class="col-md-6"><h2>Senarai Admin</h2></div>
        <div class="col-md-6">
          <div class="d-flex justify-content-end">
            <button
              class="btn btn-outline-secondary me-2"
              @click="addAdmin"
            >
              Tambah Admin
            </button>
            <button
              class="btn btn-outline-secondary me-2"
              @click="handleDownload"
            >
              Muat turun
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container bg-white">
      <div class="row mx-0">
        <div class="col-md-12">
          <div class="container-box mt-2">
            <table class="table table-hover table-borderless mt-3">
              <thead>
                <tr>
                  <th scope="col">Nama</th>
                  <th scope="col">Email</th>
                  <th scope="col">Jenis Admin</th>
                  <th scope="col">Tindakan</th>
                </tr>
              </thead>

              <tbody>
                <tr v-if="isLoading">
                  <td colspan="4">
                    <div class="w-100 py-2 d-flex justify-content-center">
                      <div class="spinner-border mt-2" role="status"></div>
                    </div>
                  </td>
                </tr>
                <template v-if="!isLoading">
                  <tr v-for="admin in admins" :key="admin.id">
                    <td>{{ admin.username }}</td>
                    <td>{{ admin.email }}</td>
                    <td>{{ admin.admin_type }}</td>
                    <td>
                      <div class="w-100 d-flex justify-content-evenly">
                        <button
                          class="btn btn-sm btn-warning"
                          style="padding: 5px !important"
                          @click="addAdmin(admin)"
                        >
                          <span class="mx-2 cursor-pointer"
                            ><i class="fas fa-pen"></i
                          ></span>
                        </button>
                        <button
                          class="btn btn-sm btn-danger"
                          style="padding: 5px !important"
                          @click="deleteClicked(admin)"
                        >
                          <span class="mx-2"><i class="fas fa-trash"></i></span>
                        </button>
                      </div>
                    </td></tr
                ></template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <modal-admin
      v-if="isModalVisible"
      @close="closeModal"
      :user="selectedUser"
    ></modal-admin>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import ModalAdmin from "../../components/ModalAdmin.vue";
import API from "../../utils/API";
import * as XLSX from "xlsx";

export default {
  components: { ModalAdmin },
  data() {
    return {
      isModalVisible: false,
      isLoading: true,
      admins: null,
      selectedUser: null,
    };
  },
  methods: {
    addAdmin(user) {
      user ? (this.selectedUser = user) : (this.selectedUser = null);
      this.isModalVisible = true;
    },
    closeModal(e) {
      this.isModalVisible = false;
      this.selectedUser = null;

      if (e) {
        this.getAdmins();
      }
    },
    async deleteClicked(user) {
    //   const swalWithBootstrapButtons = Swal.mixin({
    //     customClass: {
    //       confirmButton: "btn btn-primary",
    //       cancelButton: "btn btn-outline-secondary",
    //     },
    //     buttonsStyling: false,
    //   });

      Swal
        .fire({
          title: "Padam Admin",
          text: `Adakah anda bersetuju untuk memadam pengguna dari Tabung Haji e-learning?`,
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya",
        })
        .then(async (result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            await API.put(`users/${user.id}`, {
              blocked: true
            });
            this.getAdmins()
          }
        });
    },
    getAdmins() {
      this.isLoading = true;
      // API.get(`users?populate=*&sort[0]=id:asc`)
      API.get(`users?filters[admin_type][$notContainsi]&populate=*&sort[0]=id:asc`)
        .then((res) => {
          this.admins = res.data
            .filter(
              (user) => user.role.name == "Administrator" && !user.blocked
            )
            .sort((a, b) => b.id - a.id);
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    handleDownload(){
      const headers = [
          "#",
          "Nama",
          "Emel",
          "Jenis Admin",
      ];

      let data4Excel = this.admins.map((admin, ind) => {
        return [
          ind + 1,
          admin.username,
          admin.email,
          admin.admin_type,
        ]
      })

      data4Excel = [headers, ...data4Excel]
      const ws = XLSX.utils.aoa_to_sheet(data4Excel);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
      /* generate file and send to client */
      XLSX.writeFile(wb, "senarai_admin.xlsx");
    }
  },
  mounted() {
    this.getAdmins();
  },
};
</script>